export default Alpine.data('alpineSignaturePad', (value) => ({
    signaturePadInstance: null,
    value: value,
    resizeCanvas() {
        const ratio =  Math.max(window.devicePixelRatio || 1, 1)
        const canvas = this.$refs.signature_canvas

        if (!canvas) {
            // If the canvas isn't rendered yet, wait a little longer and try again
            window.setTimeout(() => this.resizeCanvas(), 100)
            return;
        }

        if (canvas.offsetHeight * ratio < 100) {
            // If this is small, it means the page hasn't transition hasn't finished yet
            // So wait a little longer and try again
            window.setTimeout(() => this.resizeCanvas(), 100)
            return;
        }
        canvas.width = canvas.offsetWidth * ratio
        canvas.height = canvas.offsetHeight * ratio
        canvas.getContext("2d").scale(ratio, ratio)
        this.value = null
    },
    clear() {
        this.value = null;
        this.signaturePadInstance.clear()
    },
    async init(){
        await this.$nextTick()
        this.signaturePadInstance = new SignaturePad(this.$refs.signature_canvas)
        this.signaturePadInstance.addEventListener("endStroke", ()=>{
            this.value = this.signaturePadInstance.toDataURL('image/png')
        })
        await this.$nextTick()
        // @todo make this dynamic because only modal switches need the extra delay
        this.resizeCanvas()
    }
}))
