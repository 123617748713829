import Sortable from 'sortablejs';
import _ from 'lodash';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import "@lottiefiles/lottie-player";
import './sentry';

window.Pusher = Pusher;

if (import.meta.env.VITE_PUSHER_APP_KEY && window.isAuthenticatedPage) {
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: import.meta.env.VITE_PUSHER_APP_KEY,
        cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
        forceTLS: true
    });
}

window._ = _;

import axios from 'axios';
window.axios = axios;
import moment from 'moment-timezone';
window.moment = moment;

window.makeSortable = function(element, args = {}) {
    return Sortable.create(element, args);
};


window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.withCredentials = true;
