import ImageEditor from './image-editor';
import Datepicker from './datepicker';
import SelectMultiple from './select-multiple';
import TypeaheadSelect from './typeahead-select';
import SearchableSelect from './searchable-select';
import SignaturePad from './signature-pad';
import Timepicker from './timepicker';
import Clipboard from './clipboard';

document.addEventListener('alpine:init', () => {
    ImageEditor,
    Datepicker,
    SelectMultiple,
    TypeaheadSelect,
    SearchableSelect,
    SignaturePad,
    Timepicker,
    Clipboard
})
