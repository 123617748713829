export default Alpine.data('timepicker', (model, maxTime, minTime, clearable, dataCy) => ({
    model,
    picker: null,
    maxTime: maxTime,
    minTime: minTime,
    handleUpdate: (selectedDates, dateStr, alpineObject) => {
    let dateObject = window.moment(dateStr, 'H:mm A')
    if (! selectedDates[0] && clearable) {
      alpineObject.model = ''
      return
    } else if (! dateObject.isValid()) {
      return;
    }
    alpineObject.model = dateObject.format('H:mm')
  },
    init() {
    this.$watch('model', value => {
      this.syncDate(value)
    })

    let config = {
      enableTime: true,
      noCalendar: true,
      dateFormat: 'h:i K',
      defaultDate: this.model ? window.moment(this.model, 'H:mm').format('H:mm') : null,
      allowInput: true,
      maxTime: this.maxTime,
      minTime: this.minTime,
      altInput: true,
      altFormat: 'h:i K',
      disableMobile: true,
      minuteIncrement: 1,
      onChange: (selectedDates, dateStr) => {
        this.handleUpdate(selectedDates, dateStr, this)
      },
      onClose: (selectedDates, dateStr) => {
        this.handleUpdate(selectedDates, dateStr, this)
      },
    }

    this.picker = flatpickr(this.$refs.input, config)

    const inputMaskFormat = "HH:MM TM"
    const placeholder = 'HH:MM AM'
    let inputElement = window.document.querySelector(`#${this.$refs.input.id}` + " ~ input")

    if (inputElement) {
      inputElement.setAttribute("placeholder", placeholder)
      inputElement.setAttribute("data-cy", dataCy)

      window.Inputmask('datetime', {
        inputFormat: inputMaskFormat,
        displayFormat: 'HH:MM AM',
        inputMode: 'datetime'
      }).mask(inputElement);
    }
  },
  syncDate(value) {
    if (value !== '') {
      this.picker.setDate(window.moment(value, 'H:mm').format('h:mm A'))
    }
  }
}))
