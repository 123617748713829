import './bootstrap';

import 'flatpickr';
import SignaturePad from 'signature_pad';
import Quill from 'quill';

import {livewire_hot_reload} from 'virtual:livewire-hot-reload'
import tippy from 'tippy.js';
import XMLFormatter from "xml-formatter";
import Inputmask from 'inputmask';
import '../css/app.css'
import heic2any from "heic2any";
import'./components';

window.XMLFormatter = XMLFormatter;
window.flatpickr = flatpickr
window.tippy = tippy
window.SignaturePad = SignaturePad
window.Inputmask = Inputmask;
window.Quill = Quill;

/**
 * Setup our helper that tracks our view height and makes sure our full screen elements work on mobile
 */
window.setViewHeight = function () {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
};

window.setViewHeight()

window.addEventListener('resize', () => {
    window.setViewHeight()
})

window.handleScrolling = function (key, scrollToTarget) {
    const selector = document
        .querySelector("[wire\\:id='" + key + "']");

    // Use the wire:id optionally - it's good for specificity when it's available,
    // But in rare cases the ID we're passed no longer is rendered on the page
    if (selector) {
        selector.querySelector(scrollToTarget)
            ?.scrollIntoView({behavior: 'smooth'})
    } else {
        document.querySelector(scrollToTarget)
            ?.scrollIntoView({behavior: 'smooth'})
    }

    document
        .querySelector("[wire\\:id='" + key + "']")
        ?.querySelector(scrollToTarget)
        ?.scrollIntoView({behavior: 'smooth'})
}

window.addEventListener('scrollToError', (ev) => {
    const key = ev.detail.key
    const field = ev.detail.field

    window.handleScrolling(key, "[for=" + CSS.escape(field) + '], [data-scroll-to=' + CSS.escape(field) + ']');
});

window.addEventListener('scrollToElement', (ev) => {
    window.setTimeout(() => {
        const key = ev.detail.key
        const elementSelector = ev.detail.selector;

        window.handleScrolling(key, elementSelector)
    }, 100)
});


// Trigger document downloading
document.addEventListener('printDocuments', function(event) {
    event.detail.documents.forEach((url) => window.open(url))
})

// Trigger document downloading
document.addEventListener('openUrl', function(event) {
    window.open(event.detail.url);
})

if (import.meta.env.PROD === false && !import.meta.env.VITE_DISABLE_HOT_RELOADING) {
    livewire_hot_reload()
}

window.scrollIframeToTop = function () {
    sendMessage({ reset_to_top: true });
}

window.setIframeHeight = function() {
    let currentHeight = document.getElementById('app').offsetHeight
    sendMessage({ if_height: currentHeight });
}

function sendMessage(message)
{
    var parentUrl = decodeURIComponent(document.location.hash.replace(/^#/, ''));
    if (parentUrl.indexOf('about:srcdoc') > -1 || !parentUrl) {
        parentUrl = '*';
    }
    message.iframeId = window.fetchIframeId();
    message.version = 2
    if (window.parent && parentUrl) {
        try {
            window.parent.postMessage(message, parentUrl)
        } catch (error) {
            console.error('could not sent PostMessage')
            console.error(error)
        }
    }
}
/**
 * The iframe ID should be passed as a GET parameter, so we can retrieve it here
 */
window.fetchIframeId = function() {
    var result = '';
    location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
            var tmp = item.split("=");
            if (tmp[0] === 'iframeId') {
                result = decodeURIComponent(tmp[1]);
            }
        });
    return result;
}
