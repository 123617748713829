import * as FilePond from 'filepond';
import FilePondPluginImageEditor from '@pqina/filepond-plugin-image-editor/dist/FilePondPluginImageEditor';
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import {
    openEditor,
    createDefaultImageReader,
    createDefaultImageWriter,
    blobToFile,
    processImage,
    getEditorDefaults,
} from '@pqina/pintura/pintura';
import heic2any from "heic2any";

window.FilePond = FilePond;

FilePond.registerPlugin(
    FilePondPluginImageEditor,
    FilePondPluginFileRename,
    FilePondPluginFileValidateType
);

export default Alpine.data('filepond', (livewireInstance, model) => ({
    pond: null,
    init() {
        this.$nextTick(() => {
            const options = {
                allowImagePreview: false,
                acceptedFileTypes: [
                    'image/jpg',
                    'image/jpeg',
                    'image/png',
                    'image/gif',
                    'image/heic',
                ],
                filePosterMaxHeight: 256,
                imageEditor:  {
                    // used to create the editor, receives editor configuration, should return an editor instance
                    createEditor: openEditor,

                    // Required, used for reading the image data
                    imageReader: [
                        createDefaultImageReader,
                        {
                            preprocessImageFile: async (file, options, onprogress) => {
                                // If is not of type HEIC we skip the file
                                if (!/heic/.test(file.type)) return file;

                                // Let's turn the HEIC image into JPEG so the browser can read it
                                const blob = await heic2any({
                                    blob: file,
                                    toType: 'image/jpeg',
                                    quality: 0.98,
                                });

                                // The editor expects a File so let's convert our Blob
                                return blobToFile(blob, file.name);
                            },
                        },
                    ],

                    // optionally. can leave out when not generating a preview thumbnail and/or output image
                    imageWriter: [
                        createDefaultImageWriter,
                        {
                            targetSize: {
                                width: 1028,
                                height: 1028,
                                fit: 'cover',
                            },
                        }
                    ],

                    // used to generate poster images, runs an editor in the background
                    imageProcessor: processImage,

                    // Pintura Image Editor properties
                    editorOptions: {
                        // pass the editor default configuration options
                        ...getEditorDefaults(),
                        // we want a square crop
                        imageCropAspectRatio: 1,
                    },
                },
                imageEditorInstantEdit: true,
                labelIdle: this.$refs.input.dataset.label,
                imageCropAspectRatio: 1,
                server: {
                    revert: (filename, load, error) => {
                        livewireInstance.removeUpload(model, filename, load)
                    }
                },
                imageEditorSupportImageFormat: (file) => /image/.test(file.type),
                onpreparefile: (file, output) => {
                    console.log(file, output)
                    livewireInstance.upload(model, output)

                },
                credits: {}
            }

            this.pond = FilePond.create(this.$refs.input, options);
            window.addEventListener('pondReset', e => {
                this.pond.removeFiles();
            })
        })
    }
}))
