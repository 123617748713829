export default Alpine.data('typeaheadSelect', (model) => ({
    model,
    showOptions: false,
    search: '',
    maxWidth: 0,
    async init() {
        await this.$nextTick()
        this.$refs.searchBox.focus()
        this.syncMaxWidth()
        window.addEventListener('resize', () => {
            this.syncMaxWidth()
        })
    },
    get options() {
        return Array.from(this.$refs.optionsList.options).map((item) => {
            return {
                text: item.innerText,
                // Parse as Numbers if needed
                // @see https://stackoverflow.com/questions/825402/why-does-isnan-string-with-spaces-equal-false
                value: isNaN(parseInt(item.value)) ? item.value : _.toNumber(item.value)
            }
        })
    },
    get searchedOptions() {
        if (this.search.length === 0) {
            return this.options
        }
        return this.options.filter(
            option => option.text.toLowerCase().includes(this.search.toLowerCase())
        )
    },
    get selectedText() {
        if (this.model) {
            return this.options.find(option => option.value === this.model).text
        }
    },
    get dropdownIsAboveElement() {
        // If the Y value is negative, it means it's above the select element
        return this.$refs.anchorItem?._x_anchor?.y < 0
    },
    syncMaxWidth() {
        // If this has been closed in the meantime, bail
        if (!this.$refs.input) {
            return;
        }
        this.maxWidth = this.$refs.input.offsetWidth
        if (this.maxWidth === 0) {
            window.setTimeout(() => {
                this.syncMaxWidth()
            }, 200)
        }
    },
    toggleOptions() {
        this.showOptions = !this.showOptions
        if (this.showOptions) {
            this.$nextTick(() => this.$refs.searchBox.focus())
        }
    },
    updateModel(option) {
        this.showOptions = false;
        if (option.value === '') {
            this.model = null;
        } else {
            this.model = option.value;
        }
    }
}))
