import * as Sentry from "@sentry/browser";

window.Sentry = Sentry.init({
    ...window.SENTRY_CONFIG,
    ignoreErrors: [
        "Non-Error promise rejection captured",
        "is not defined",
        "is undefined",
        "Can't find variable",
        "Component not found",
    ],
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.thirdPartyErrorFilterIntegration({
            filterKeys: ["shelterluv"],
            behaviour: "drop-error-if-contains-third-party-frames",
        }),
    ],
});
