export default Alpine.data(
    'searchableSelect',
    (callbackUrl,
     text,
     value,
     model,
     entangledModel,
     defaultValue,
     exclude,
     urlId,
     createOption,
     clearable,
     createEvent,
     createOptionText) => ({
        url: callbackUrl,
        text: text,
        value: value,
        model: entangledModel,
        modelText: defaultValue,
        options: [],
        exclude: exclude,
        showOptions: false,
        search: '',
        maxWidth: 0,
        createOption,
        createOptionText,
        async init() {
            await this.$nextTick()
            this.syncMaxWidth()
            window.addEventListener('resize', () => {
                this.syncMaxWidth()
            })
        },
        callbackSearch() {
            let url = new URL(document.getElementById(urlId).value);
            url.searchParams.append('search', this.search);
            url.searchParams.append('disable-pagination', 1);
            axios.get(url).then((result) => {
                let options = result.data;
                let modelMatch = null;

                if (this.model) {
                    modelMatch = options.find(item => item.id == this.model);
                }
                this.options = options.filter(item => !this.exclude.includes(item.id)).map((item) => {
                    return {
                        text: item[this.text],
                        value: item[this.value],
                        data: item
                    }
                });
                if(clearable) {
                    this.options.unshift({
                        text: '(Clear Selection)',
                        value: ''
                    });
                }
                if (!defaultValue && modelMatch) {
                    this.modelText = modelMatch[this.text];
                }
            });
        },
        async toggleOptions() {
            this.showOptions = !this.showOptions
            await this.$nextTick()
            await this.$nextTick()
            if (this.showOptions) {
                this.$refs.searchBox.focus()
            } else {
                this.$refs.input.focus()
            }
        },
        async updateModel(option) {
            this.showOptions = false;
            if (option.value === '') {
                this.model = null;
                this.modelText = '';
            } else {
                this.model = option.value;
                this.modelText = option.text;
            }

            await this.$nextTick()
            this.$refs.input.focus()
        },
        create() {
            this.$dispatch(createEvent);
        },
        clearModel(event) {
            if (event.detail.model === model) {
                this.modelText = '';
                this.search = '';
                this.showOptions = false;
                this.callbackSearch()

                if (event.detail.clearModel) {
                    this.model = '';
                }
            }
        },
        syncModelText(event) {
            if (event.detail.model === model) {
                this.modelText = event.detail.text;
            }
        },
        syncMaxWidth() {
            // If this has been closed in the meantime, bail
            if (!this.$refs.input) {
                return;
            }
            this.maxWidth = this.$refs.input.offsetWidth
            if (this.maxWidth === 0) {
                window.setTimeout(() => {
                    this.syncMaxWidth()
                }, 200)
            }
        },
        get dropdownIsAboveElement() {
            // If the Y value is negative, it means it's above the select element
            return this.$refs.anchorItem?._x_anchor?.y < 0
        },
    }))
